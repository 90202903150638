import React from "react";

const HomeComponent = React.lazy(() =>
  import("views/app-views/dashboards/home/index")
);
const SettingsComponent = React.lazy(() =>
  import("views/app-views/dashboards/settings/index")
);
const DevicesComponent = React.lazy(() =>
  import("views/app-views/dashboards/devices/index")
);

const LandingPage = React.lazy(() => import("views/app-views/landing"));
const LoginComponent = React.lazy(() =>
  import("views/auth-views/authentication/login")
);
const RegisterComponent = React.lazy(() =>
  import("views/auth-views/authentication/register")
);
const ForgotPasswordComponent = React.lazy(() =>
  import("views/auth-views/authentication/forgot-password")
);

export {
  DevicesComponent,
  ForgotPasswordComponent,
  HomeComponent,
  LandingPage,
  LoginComponent,
  RegisterComponent,
  SettingsComponent
};

