import fetch from 'auth/FetchInterceptor'

const scaleLogsService = {}

scaleLogsService.getScaleLogs = function (params) {
  return fetch({
    url: '/scale-logs',
    method: 'get',
    params
  })
}

export default scaleLogsService