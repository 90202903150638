import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import scaleLogsService from "services/ScaleLogsService";

export const initialState = {
  logsLoading: false,
  message: "",
  showMessage: false,
  avgLoadedTruckWeight: 0,
  loadedTruckCount: 0,
  graphXAxisLoadedTrucks: [],
  graphYAxisLoadedTrucks: [],
  status: false,
};

export const getScaleLogs = createAsyncThunk(
  "get-scale-logs",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data)
      const response = await scaleLogsService.getScaleLogs(data);

      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const scaleLogsSlice = createSlice({
  name: "scaleLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScaleLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScaleLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.avgLoadedTruckWeight = action.payload.data.averageLoadedWeight ? action.payload.data.averageLoadedWeight : 0
        state.loadedTruckCount = action.payload.data.loadedTruckCount ? action.payload.data.loadedTruckCount : 0
        state.graphXAxisLoadedTrucks = action.payload.data.graphXAxisLoadedTrucks ? action.payload.data.graphXAxisLoadedTrucks : []
        state.graphYAxisLoadedTrucks = action.payload.data.graphYAxisLoadedTrucks ? action.payload.data.graphYAxisLoadedTrucks : []
      })
      .addCase(getScaleLogs.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {} = scaleLogsSlice.actions;

export default scaleLogsSlice.reducer;
